import differenceWith from 'lodash.differencewith'
import { sample, isEqual } from '@s-libs/micro-dash'
import { bannedWordContains, bannedWordCustomContains, bannedWords } from '../data/data'
import { names } from '../data/names'

import chiccaLogo from '../assets/images/chicca-icon-hires.webp'
// import store from '../store'
import moment from 'moment-timezone'

const COMMON_STRINGS = Object.freeze({
    NEAR_BY_HEADER: "Explore",
    NEAR_BY: "Who's Nearby Now",
    CHICCA: "ChiccaChicca",
    CHICCA_CHICCA: 'ChiccaChicca',
    POST_AD: "Post an Ad",

    CREATE_PROFILE: "Create your profile",
    NEW: 'NEW',
    ONLINE: 'Online',
    INCOGNITO: 'Incognito',
    ONLINE_NOW: 'Online Now',
    YOURE_ONLINE: "You are now online",
    OFFLINE: 'Offline',
    FILTER: 'Filter',
    GENDER: 'Gender',
    AGE: 'Age',
    PRICE: 'Price',
    ETHNICITY: 'Ethnicity',

    SELECT_TYPE: 'Filter Escorts',

    ADS_WITH_VIDEO: 'Ads with Videos',
    NEW_LISTING: 'New Listings',
    ESCORT_TYPE: 'Escort Type',
    TYPE_OF_AD: 'Type of Ad',
    LANGUAGE: 'Language',
    SERVICES: 'Services',
    SERVICES_FOR: 'Services For',
    SERVICES_FOR_WHO: 'Services for who',
    MY_SERVICES_FOR: 'My Services is for',
    RATES: 'Rates',
    INCALL: 'Incall',
    OUTCALL: 'Outcall',
    DECSRIPTION: 'Description',
    VIEW_FAVORITE: 'View Favorites',
    BLOCK: 'Block',
    REPORT_AD: 'Report this Profile',
    CONCERN: 'Concern for Escort',
    SPAM: 'Spam',
    BLOCK_QUESTION: 'Do you want to end this [type] and block this user?',
    BLOCK_QUESTION_1: 'Are you sure you want to block this [type]?',
    DELETE_CONFIRMATION: 'Are you sure you want to delete this advertisement?',
    DELETE_CONFIRMATION_LAST_AD: 'This is your last ad, once deleted, you will not be displayed online until you post a new advertisement.',
    YES: 'Yes',
    NO: 'No',
    OKAY: 'Okay',
    CANCEL: 'Cancel',
    CLOSE: 'Close',
    CREDIT_ACCOUNT: "Credit your account",
    HELP: "Help",
    SPAM_QUESTION: 'Do you want to mark this profile as spam?',
    REPORT_QUESTION: "Describe why you want to report this profile below",

    REPORT_DESCRIPTION: "Please provide the name, phone number or link of the ad that you're referring to.",
    REPORT_DESCRIPTION2: "If you provide no name, phone number or link, we won't have access to sufficient information to process your request",
    PLATFORM_FEEDBACK: "What's your feature request or feedback?",
    SEND_CONCERN: 'Describe your concerns below',
    SEND_CONCERN_DESCRIPTION: "Please provide the name, phone number or link of the ad that you're referring to.",
    SEND_CONCERN_DESCRIPTION2: "If you provide no name, phone number or link, we won't have access to sufficient information to process your request",
    TYPE_MESSAGE: 'Type your message..',
    FAVORITE: 'Favourites',
    NO_AD_AVAILABLE: 'No Ads available, try changing the filter.',
    LOADING_ADS: 'Ads for your gender selection loading now.',
    NO_AD_AVAILABLE_NEARBY: 'No Ads available nearby, try changing the location.',
    NO_FAVORITE: 'No Favourites yet',
    NO_FAVORITE_DETAIL: 'Tap the star on a profile to save them here',
    CLICK_REFRESH: 'Click to refresh',
    PROCEED_WITHOUT_LOCATION: 'Proceed without location',
    CALL_ACCEPTED: 'Call Accepted',
    CALL_REJECTED: 'Call Rejected',
    CALLING: 'Calling [name]...',
    NO_ANSWER: 'No Answer',
    CANCELLED: 'Call Cancelled',
    OUTGOING_CALL: 'Outgoing Call',
    INCOMING_CALL: 'Incoming Call',
    MUTE: 'Mute',
    SPEAKER: 'Speaker',
    SEARCH_MESSAGE: 'Search Messages',
    SEARCH_CALL: 'Search Calls',
    MESSAGE_RECEIVED: 'Message received',
    CALL_RECEIVED: 'Call received',
    ENTER_EMAIL: 'Enter Email',
    EMAIL_ADDRESS: 'Email address',
    RE_ENTER_EMAIL: 'Re-enter email address',
    ENTER_PASSWORD: 'Enter Password',
    RE_ENTER_PASSWORD: 'Re-enter Password',
    ENTER_YOUR_EMAIL: 'Enter your email',
    ENTER_YOUR_NEW_EMAIL: 'Enter your new email',
    RE_ENTER_YOUR_NEW_EMAIL: 'Re-enter your new email',
    THANK_YOU: "Thank you for using ChiccaChicca.",

    USERNAME: 'Username',
    ENTER_YOUR_MOBILE_NUMBER: 'Enter your mobile number',
    PASSWORD: 'Password',
    LOGIN: 'Login',
    ESCORT_SIGN_UP: "Escort Sign Up",
    FORGOT_PASSWORD: 'Forgot your Password?',
    REGISTER_ACCOUNT: 'Register for a FREE account',
    REGISTER_NOW: 'Sign Up Now',
    NEXT: 'Next',
    PUBLISH_AD: 'Publish your profile',
    PROFILE_NAME: 'Profile name',
    PROFILE_HEADLINE: 'Headline - displayed on your ad',
    PROFILE_HEADLINE_DESCRIPTION: 'Show off a name, slogan or specialty',
    PROFILE_DESCRIPTION: 'Profile description',
    INCALL_ADDRESS: 'Incall Address',
    INCALL_ADDRESS_DETAILS: 'This is not displayed publicly. This is for when you decide to meet the client and you can send your address by a quick press. This is specific to this profile and can be changed any time in My Accounts and editing the profile.',
    AD_HEADLINE: 'Ad headline (max 15 characters)',
    YOU_ARE: 'You are',
    ADD_PICTURE: 'Add Pictures',
    CARD_HOLDER_NAME: "Card Holder Name",
    CARD_NUMBER: 'Card Number',
    AMOUNT: "Enter Amount",
    REDIRECT_PAYMENT: "You will be redirected to a secured payment page",
    EXPIRES_ON: 'Expires on',
    SECURITY_CODE: 'Security Code',
    SELECT_PREFERRED_GENDER: "Please select preferred gender:",
    AGREE_PROCEED: "Agree and Proceed",
    LEAVE_WEBSITE: "Leave Website",
    CHAT_HINT: "Say something..",
    ENTER_OTP: "Enter the verification code sent to your email",
    ENTER_OTP_SMS: "Enter verification code sent by SMS",
    SUBMIT: "Submit",
    RESEND_CODE: "Resend code",
    CREATE_ACCOUNT: "Create an Account",
    REGISTER_USER_AGREEMENT: "By clicking register now, I agree to ChiccaChicca's [terms], [guidelines] and accept the [policy]",
    POSTING_AGREEMENT: "I agree to ChiccaChicca's [terms] Posting Guidelines and accept the [policy]",
    TERMS_URL: "/terms",
    POLICY_URL: "/policy",
    POSTING_GUIDELINES: '/guidelines',
    DISCLAIMER_URL: "google.com",
    PROCEED: "Proceed",
    NO_ADS: "You haven't posted an ad yet",
    WELCOM_CHICCA: "Welcome to ChiccaChicca!",
    AUTO_MESSAGE: "Hi [name], are you free to meet?",
    BROWSE_PROFILE: "Browse other profiles",
    GO_BACK_BROWSING: "Go back to browsing",
    SEND_MESSAGE : "Send a message",
    WAIT_USER_TO_RESPOND: "Wait for [name] to respond?",
    CONTINUE_CHATTING: "Continue chatting",
    IGNORE_MESSAGE: "Ignore message",
    TOP_UP_WALLET: "Top Up Wallet",
    LEAVE_CALL_MESSAGE: "Leaving this page will end the call. Are you sure you want to leave?",
    SAVE: "Save",
    CALL_ALERT: "The call is about to reach [time] mins.",
    CONTINUE: "Continue",
    DO_NOT_SHOW_NOTIFICATION: "Do not show this message again",
    MAXIMUM_CALL_EXCEEDED: "Maximum call duration exceeded",

    MESSAGE_NOTIF: "[message] messages remaining (both users).",
    MESSAGE_ALERT: "Maximum messages exceeded",
    BLOCK_ALERT1: "[name] has blocked you.",

    BLOCK_STRIKE1: "You have [strike] strikes left before you are banned",
    BLOCK_STRIKE2: "You have received 3 strikes. You are no longer able to use the service.",
    BLOCK_ALERT2: 'You will not be charged for this call',
    BLOCK_ALERT3: 'You are currently blocked by this user',
    MAXIMUM_CALL_MESSAGE: "Maximum call duration : [time] mins.",
    REMAINING_CALL_DURATION: "Remaining call duration : ",
    NOTE: "Note",
    MESSAGE_DETAIL: "[limit] messages per chat maximum (total messages)",
    LOCATION_PERMISSION: "It seems that the location services for this browser is not turned on. To maximize functionality, you can set the location of your device and reload the page afterwards.",
    ENTER_CURRENT_PASSWORD: "Enter Current Password",
    ENTER_NEW_PASSWORD: "Enter New Password",
    RE_ENTER_NEW_PASSWORD: "Re-Enter New Password",
    CHANGE_PASSWORD: "Change Password",
    CHECK_EMAIL: "Check your email",
    CHECK_EMAIL_DESCRIPTION: "We have sent a verification code to your email.",
    EMAIL_NEED_VERIFICATION: "Email is not yet verified",
    MOBILE_NEED_VERIFICATION: "Mobile Number is not yet verified",
    VERIFY_EMAIL: "Verify Email",
    VERIFY_ACCOUNT : "Verify Account",
    SELECT_OPTION: "Select option",
    ADS_ALREADY_DELETED: "This ad is already deleted",
    USER_NO_AUDIO: "The user's device is not supported to make this call.",
    UNSUPPORTED_BROWSER: "Your browser is not supported to make this call. Please try using other browser.",
    OLD_BROWSER: "Please update your browser to make this call",
    DEFAULT_ERROR_CALL: "Can not initiate call.",
    PUBLISH_ERROR_CALL: "Please make sure that you enable your mic permission to be able to make this call.",

    CHECKING_BROWSER: "Please wait while we check your browser compatibility",
    SIGN_UP: "Sign Up",
    SIGN_ME_UP: "Sign me up"
})

const ERRORS = Object.freeze({
    EMAIL_REQUIRED: "Email is required",
    INVALID_EMAIL: "Invalid Email",
    PASSWORD_REQUIRED: "Password is required",
    PASSWORD_DOES_NOT_MATCH: "Entry mismatch",
    ENTRY_MISMATCH: "Entry mismatch",
    NAME_REQUIRED: "Profile name is required",
    GENDER_REQUIRED: "Gender is required",
    DESCRIPTION_REQUIRED: "Description is required",
    LANGUAGE_REQUIRED: "Select at least one language",
    SERVICES_FOR_REQUIRED: "Select which gender(s) your services are for",
    SERVICE_REQUIRED: "Select at least one service",
    IMAGE_REQUIRED: "Select at least one image",
    VIDEO_REQUIRED: "Select at least one video",
    FIELD_REQUIRED: "This field is required",
    PHONE_REQUIRED: "Mobile number is required",
    CAPTCHA_ERROR: "Please verify that you are not a robot",
    AMOUNT_NOT_ZERO: "Amount should be greater than 0",
    USER_HAS_INSUFFICIENT_FUND: "Something is wrong with the seller's account. Unable to send your message.",
    INSUFFICIENT_BALANCE: "You have insufficient balance, Please top up your account",
    STRIKE_MESSAGE: "You have received 3 strikes. You are no longer able to use the service.",
    BLOCK_MESSAGE: "You are not allowed to communicate with this user",
    COMMON_SELLER_CALL_ERROR: "Unable to call seller. Please try again later",
    VERIFYING_IMAGE: "Please wait while we verify your image(s)",
    COUNTRY_NOT_ALLOWED: "ChiccaChicca currently only supports site users within the UK, so our call and messaging features are not yet available to non-UK users",
    COUNTRY_NOT_ALLOWED_SELLER: "ChiccaChicca currently only supports site escorts within the UK",
 
    LOCATION_FOR_SERVICE_REQUIRED: "To enable call and chat feature, please turn on your location services.",
    CONTAINS_BAN_WORDS: "Your profile cannot include contact details or social media-related terms.",
    LOCATION_REQUIRED_POST_AD: "It seems that the location services for this browser is not turned on. To add a new profile, you need to set the location of your device and reload the page afterwards.",
})

const GENDERS = Object.freeze({
    FEMALE: {
        NAME: "Female",
        VALUE: 0
    },
    MALE: {
        NAME: "Male",
        VALUE: 1
    },
    TRANSGENDER: {
        NAME: "Transgender",
        VALUE: 2
    }
    ,
    ALL: {
        NAME: "All",
        VALUE: null
    }
})

const getGenderIndex = (gender) => {
    if (gender == "Female")
        return 0
    if (gender == "Male")
        return 1
    return 2
}

const getGenderByIndex = (gender) => {
    if (gender == 0)
        return "Female"
    if (gender == 0)
        return "Male"
    return "Trans"
}

const ACTIONS = Object.freeze({
    SELECTED: "SELECTED",
    BACKGROUND_FETCH: "BACKGROUND_FETCH",
    FAVOURITE_ADD: "FAVOURITE_ADD",
    FAVOURITE_REMOVE: "FAVOURITE_REMOVE",
    FAVOURITE_REMOVE_ALL: "FAVOURITE_REMOVE_ALL",
    FAVORITE_UPDATE_STATUS: "FAVORITE_UPDATE_STATUS",
    NOTIFICATION: "NOTIFICATION",
    LOCATION_ADD: "LOCATION_ADD",
    LOCATION_REMOVE: "LOCATION_REMOVE",
    CONTACT: "CONTACT",
    LOCATION_REMOVE_ALL: "LOCATION_REMOVE_ALL",
    SPAM_ADD: "SPAM_ADD",
    SPAM_REMOVE: "SPAM_REMOVE",
    AUTH: "AUTH",
    GENDER_MODAL: "GENDER_MODAL",
    DISCLAIMER: 'DISCLAIMER',
    MESSAGE: 'MESSAGE',
    MAP: 'MAP',
    NOTICE: "NOTICE",
    SELECT_GENDER: "SELECT_GENDER",
    NEARBY: "NEARBY",
    SELECT_MESSAGE: "SELECT_MESSAGE",
    WELCOME_MESSAGE: "WELCOME_MESSAGE",
    SELLER_VISIT_AD: "SELLER_VISIT_AD",
    USER_ADS: "USER_ADS",
    AD_LIST: "AD_LIST",
    AD_LIST_UPDATE_STATUS: "AD_LIST_UPDATE_STATUS",
    SELECT_USER_AD: "SELECT_USER_AD",
    DEVICE_INFO: "DEVICE_INFO",
    CURRENT_USER: "CURRENT_USER",
    SESSION: "SESSION",
    BALANCE: "BALANCE",
    BALANCE_ALERT: "BALANCE_ALERT",
    PROGRESS: "PROGRESS",
    VERIFY: "VERIFY",
    SYSTEM_NOTIFICATION: "SYSTEM_NOTIFICATION",
    READ_SYSTEM_NOTIF: "READ_SYSTEM_NOTIF",
    AGORA: "AGORA",
    AGORA_STATUS: "AGORA_STATUS",
    SMS: "SMS",
    SERVICE_SETTING: "SERVICE_SETTING",
    ADD_TO_HOME: "ADD_TO_HOME",
    SMS_REMOVE: "SMS_REMOVE",
    SMS_MARK_READ: "SMS_MARK_READ",
    SMS_MARK_SENT: "SMS_MARK_SENT",
    SMS_MARK_CLEAR: "SMS_MARK_CLEAR",
    CALL_HISTORY: "CALL_HISTORY",
    CALL_HISTORY_REMOVE: "CALL_HISTORY_REMOVE",
    CALL_MARK_READ: "CALL_MARK_READ",
    CALL: "CALL",
    INCOMING_CALL: "INCOMING_CALL",
    REQUEST: "REQUEST",
    RECEIVED_MESSAGE: "RECEIVED_MESSAGE",
    BLACKLIST: "BLACKLIST",
    TOP_UP: "TOP_UP",
    LOGOUT: "USER_LOGGED_OUT",
    ADDRESS: "ADDRESS",
    FILTER_ADLIST: "AD_LISTFILTER",
    ADLIST_STATUS: "ADLIST_STATUS",
    ADDRESS_DETAILS: "ADDRESS_DETAILS",
    CHANGE_LOCATION: "CHANGE_LOCATION",
    MESSAGE_ALERT: "MESSAGE_ALERT",
    BLOCK_ALERT: "BLOCK_ALERT",
    TOP_BAR: "TOP_BAR",
    SETTING: "SETTING",
    RESULT_BLOCK: "RESULT_BLOCK",
    TUTORIAL: "TUTORIAL",
    SELLER_TUTORIAL : "SELLER_TUTORIAL",
    AD_TUTORIAL: "AD_TUTORIAL",
    LOCATION_PERMISSION: "LOCATION_PERMISSION",
    SELECTED_NOTIF: "SELECTED_NOTIF",
    BUYER_INFO: "BUYER_INFO",
    CURRENT_TIME: "CURRENT_TIME",
    SW_UPDATE: "SW_UPDATE",
    SW_INIT: "SW_INIT",
    SIGN_UP: "SIGN_UP"
})


const PROGRESS_TYPE = Object.freeze({
    PROGRESS: "PROGRESS",
    SUCCESS: "SUCCESS",
    FAILED: "FAILED",
})



const API_URL = Object.freeze({
    // USERS: "home_new",
    USERS: "home",
    LOGIN: "login",
    AVATAR: "avatar",
    LOGOUT: "logout",
    CHANGE_PASSWORD: "changePass",
    REGISTER: "register",
    AGORA_TOKEN: "getAgoraToken",
    NEW_AGORA_TOKEN: "agoraToken",
    // AGORA_TOKEN: "agoraToken",
    CHECKOUT: "checkout",
    // USER_PROFILE: 'profile',
    USER_PROFILE: 'profile',
    USER_AD: 'getAd/:id',
    DELETE_AD: 'delAd/:id',
    REPORT: "user/report",
    SPAM: "user/spam",
    FEEDBACK: "feedback",
    BLOCK: "user/block",
    MESSAGES: "user/messages",
    MESSAGE_LIST: "user/message_list",
    SEND_MESSAGE: 'user/message_send',
    POST_AD: 'plusAd',
    AD_SWITCH: "adSwitch",
    USER_ADS: 'user/ads',
    CURRENT_USER: 'user/account',
    TRANSACTIONS: "transaction",
    AD_REPORT: "adReport",
    CREATE_PAYMENT: "createPay",
    CONFIRM_PAYMENT: "toPay",
    FILE_MANAGE: "fileManage",
    BALANCE_INQUIRY: "balanceQuery",
    SAVE_MESSAGE: "saveMessage",
    UPLOAD_FILES: "uploadFile",
    SAVE_RATES: "saveRate",
    SAVE_ADDRESS: "saveAdAddress",
    SAVE_BUYER : "buyerSave",
    GET_ADDRESS: 'getAdAddress',
    BLOCK_USER: 'blackSave',
    GET_BLOCK_USERS: "blackInfo",
    DELETE_BLOCK: "blackRemove",
    SAVE_CHAT: "saveChat",
    SAVE_CALL: "saveCall",
    SETTING: "setting",
    FORGOT: "forget",
    VERIFY_AUTH: 'verifyAuthorization',
    RESET: "reset",
    VERIFY_EMAIL: "emailVerify",
    VERIFY_MOBILE: "mobileVerify",
    BUYER: "buyer",
    SERVICE_CHAT: "service/chat",
    SERVICE_CALL: "service/call",
    VERIFY_MEDIA: "verifyMedia",
    UPLOAD_MEDIAS: "upMedia",
    NOTIF_LIST: "notice",
    NOTIF_READ: "noticeInfo/:id",
    CHAT_LIST: "chatList",
    CONTACTS: "contacts",
    SET_READ: "setRead",
    REGISTER1: "register2"
})



const MESSAGE_TYPE = Object.freeze({
    SENDER: "SENDER",
    RECEIVER: "RECEIVER",
})

const AGORA_TYPE = Object.freeze({
    MESSAGE: "rtm",
    CALL: "rtc",
})


const CALL_STATUS = Object.freeze({
    CHECKING_BROWSER: "CHECKING_BROWSER",
    ONGOING: "ONGOING",
    ACCEPTED: "ACCEPTED",
    REJECTED: "REJECTED",
    NO_ANSWER: "NO_ANSWER",
    CANCELLED: "CANCELLED",
    CALL_ENDED: "CALL_ENDED",
    INITIALIZING: "INITIALIZING"
})


const CONNECTION_STATUS = Object.freeze({
    NOT_ESTABLISH: "NOT_ESTABLISH",
    ESTABLISH: "ESTABLISH",
})

const CONFIG = Object.freeze({
    SEARCH_KM: 5, // in km
    // SEARCH_KM: 10000, // in km
    RANDOM_RADIUS_IN_METER: 20, // 2 m
    FIXED_RADIUS_IN_METER: 5, // 2 m
    // FILE_MAX: 31457280, // (bytes) 30 MB,
    // FILE_MAX: 20971520, // 20 mb
    // FILE_MAX: 104857600,// 100 mb
    FILE_MAX: 52428800, // 50 mb
    // CURRENCY: '£',
    CURRENCY: '',
    LOW_BALANCE: 20,
    REFRESH_MINUTES: 20,
    DEFAULT_IMAGE: "https://chicca-bucket.s3.eu-west-2.amazonaws.com/chicca/storage/topic/20220722/63ee4b1da0230d9ee26f5b2c17dec03c.png",
    CC_RINGTONE: "https://chicca-bucket.s3.eu-west-2.amazonaws.com/chicca/storage/topic/app_assets/music/CC_call.mp3",
    CALLING_RINGTONE: "https://chicca-bucket.s3.eu-west-2.amazonaws.com/chicca/storage/topic/app_assets/music/calling.mp3",
    RINGTONE: "https://chicca-bucket.s3.eu-west-2.amazonaws.com/chicca/storage/topic/app_assets/music/ChiccaChicca.mp3",
    FEEDBACK_EMAIL: "feedback@chiccachicca.com",
    REPORT_EMAIL: "report@chiccachicca.com",
    CONCERN_EMAIL: "concerns@chiccachicca.com",


})


const BALANCE_TYPE = Object.freeze({
    LOW_BALANCE: {
        type: "LOW BALANCE",
        message: "You are running low on top up, otherwise you will not be able to respond to buyers"
    },
    INSUFFICIENT_BALANCE: {
        type: "INSUFFICIENT BALANCE",
        message: "You are receiving a call, however you have insufficient credit to accept this call. Please top up your account"
    },
})


const CALL_MESSAGE_RULE = Object.freeze({
    EXPIRATION: 1200000, // in millisecconds
    // EXPIRATION: 60000, // in millisecconds
    CALL_ALERT: 30, // 30 seconds before the ending in seconds
    CALL_DUE: 300, // in seconds
    MESSAGE_LIMIT: 20,
    MESSAGE_LIMIT_ALERT: 2,
    BLOCK_DURATION: 7 // days
})

const MESSAGE_STATUS = Object.freeze({
    BLOCK: 'BLOCK',
    LEAVE_CALL: "LEAVE_CALL",
    MESSAGE: "MESSAGE"
})

const TRANSACTION_PERIOD = Object.freeze([
    { label: "All", value: 0 },
    { label: "Today", value: 1 },
    { label: "Past Week", value: 2 },
    { label: "Past Month", value: 3 },
    { label: "Past 3 Months", value: 4 },
    { label: "Past Year", value: 5 },
])

function changePropertyValue(id, propertyToChange, valueToChange, array) {
    for (var i in array) {
        if (array[i].id == id) {
            array[i][propertyToChange] = valueToChange;
            break;
        }
    }
    return array;
}

function generalDate(date) {
    // var moment = require('moment');
    date = getConvertedTime(date);
    var dateNow = moment()
    if (dateNow.diff(date, 'days') == 0) {

        return getConvertedTime(date).format("HH:mm")
    } else {
        return getConvertedTime(date).format("DD-MM-YYYY HH:mm")
    }
}


function getFormattedDate(date, format = "DD-MM-YYYY HH:mm") {
    return moment(date).tz(getDeviceTimeZone()).format(format)
}

function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

// function jsObjectToFormData(object) {
//     const formData = new FormData();
//     Object.keys(object).forEach(key => {
//         // console.log('selfing' + key, object[key]);
//         formData.append(key, object[key])
//     });
//     return formData;
// }

function formDataToJsObject(formData) {
    var object = {};
    formData.forEach((value, key) => {
        // Reflect.has in favor of: object.hasOwnProperty(key)
        if (!Reflect.has(object, key)) {
            object[key] = value;
            return;
        }
        if (!Array.isArray(object[key])) {
            object[key] = [object[key]];
        }
        object[key].push(value);
    });
    var json = JSON.stringify(object);
    return json
}

function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}
const getDataUrl = (a) => {
    return a.prefix + "" + a.data
}
function jsObjectToFormData(obj, form, fd) {
    // fd = form || new FormData();
    // let formKey;

    // for (var property in obj) {
    //     if (obj.hasOwnProperty(property)) {
    //         formKey = property;
    //         // if the property is an object, but not a File,
    //         // use recursivity.
    //         if (Array.isArray(obj[property])) {
    //             for (let index = 0; index < obj[property].length; index++) {
    //                 const element = obj[property][index];
    //                 if (typeof (element) === 'object') {
    //                     Object.keys(element).forEach(key => {
    //                         fd.append(formKey + '[' + index + ']' + '[' + key + ']', element[key])
    //                     });
    //                 } else if (typeof (element) === 'string') {
    //                     fd.append(formKey + '[' + index + ']', element)
    //                 }
    //             }
    //         } else {
    //             const element = obj[property];
    //             if (typeof (element) === 'object') {
    //                 Object.keys(element).forEach(key => {
    //                     fd.append(formKey + '[' + key + ']', element[key])
    //                 });
    //             } else
    //                 fd.append(formKey, obj[property]);
    //         }
    //     }
    // }

    // return fd;
    return obj

};


function jsObjectToFormData1(obj, form, fd) {
    fd = form || new FormData();
    let formKey;

    for (var property in obj) {
        if (obj.hasOwnProperty(property)) {
            formKey = property;
            // if the property is an object, but not a File,
            // use recursivity.
            if (Array.isArray(obj[property])) {
                for (let index = 0; index < obj[property].length; index++) {
                    const element = obj[property][index];
                    if (typeof (element) === 'object') {
                        Object.keys(element).forEach(key => {
                            fd.append(formKey + '[' + index + ']' + '[' + key + ']', element[key])
                        });
                    } else if (typeof (element) === 'string') {
                        fd.append(formKey + '[' + index + ']', element)
                    }
                }
            } else {
                const element = obj[property];
                if (typeof (element) === 'object') {
                    Object.keys(element).forEach(key => {
                        fd.append(formKey + '[' + key + ']', element[key])
                    });
                } else
                    fd.append(formKey, obj[property]);
            }
        }
    }

    return fd;

};

function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function stringToArray(string, separator) {
    if (Array.isArray(string)) {
        string = string.toString();
    }
    string = string.replace(' ', '');
    var array = string.split(separator)
    return array
}

function cleanJsonObject(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    }
    return obj
}

function isJsonObjectEmpty(obj) {
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
            return false;
    }

    return true;
}

function getSafeImage(image) {
    try {
        if (typeof image === 'object')
            return image.data;
        else if (typeof image === 'string')
            return image;
        else return chiccaLogo
    } catch (error) {
        return chiccaLogo
    }
}

function getMiles(i) {
    return i * 0.000621371192;
}
function getKilometers(i) {
    // return i*1609.344;
    return i / 1000;
}
function getKmToM(i) {
    // return i*1609.344;
    return i * 1000;
}

function getDisplayDistance(distance) {
    var distanceInKm = Math.floor(getKilometers(distance))
    if (distanceInKm < 1) {
        return roundUpNearest(distance, 1, false) + "m away"
    } else if (distanceInKm < 3) {
        var dis = parseFloat(roundUpNearest(distance, 100)) / 1000;
        return dis + "km away"
    } else if (distanceInKm < 5) {
        var dis = parseFloat(roundUpNearest(distance, 500)) / 1000;
        return dis + "km away"
    } else {
        return Math.round(distanceInKm) + "km away"
    }
}


function roundUpNearest(num, nearest = 10, isCeil = false) {
    if (isCeil)
        return Math.ceil(num / nearest) * nearest;
    return Math.round(num / nearest) * nearest;
}

function calculateDistance(lattitude1, longittude1, lattitude2, longittude2) {

    if ((lattitude1 == 0 && longittude1 == 0) || (lattitude2 == 0 && longittude2 == 0))
        return 0
    // console.log(lattitude1, longittude1, lattitude2, longittude2,'distance')
    const toRadian = n => (n * Math.PI) / 180

    let lat2 = lattitude2
    let lon2 = longittude2
    let lat1 = lattitude1
    let lon1 = longittude1

    let R = 6378.137;  // km
    let x1 = lat2 - lat1
    let dLat = toRadian(x1)
    let x2 = lon2 - lon1
    let dLon = toRadian(x2)
    let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadian(lat1)) * Math.cos(toRadian(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    let d = R * c

    d = parseFloat(d).toFixed(7)
    // console.log(d, 'distance')
    return getKmToM(d)

    // var radLat1 = lattitude1 * Math.PI / 180.0;
    // var radLat2 = lattitude2 * Math.PI / 180.0;
    // var a = radLat1 - radLat2;
    // var b = (longittude1 * Math.PI / 180.0) - (longittude2 * Math.PI / 180.0)
    // var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)))
    // s = s * R;
    // s = Math.round(s * 1000)

    // console.log("distance==?", s)
    // return getKmToM(s)
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

// function calculateDistance(lattitude1, longittude1, lattitude2, longittude2) {


//     var distance = 2 * 6378.137 * Math.asin(
//         Math.sqrt(
//             Math.pow(Math.sin(Math.PI * (longittude1 - longittude2) / 360), 2) + Math.cos(Math.PI * lattitude1 / 180) * Math.cos(lattitude2 * Math.PI / 180) * Math.pow(Math.sin(Math.PI * (lattitude1 - lattitude2) / 360), 2)
//         ))


//     var rad = function (x) {
//         return x * Math.PI / 180;
//     };

//     var R = 6378137; // Earth’s mean radius in meter
//     var dLat = rad(lattitude2 - lattitude1);
//     var dLong = rad(longittude2 - longittude1);
//     var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
//         Math.cos(rad(lattitude1)) * Math.cos(rad(lattitude2)) *
//         Math.sin(dLong / 2) * Math.sin(dLong / 2);
//     var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//     var d = R * c;

//     console.log("distance==?", distance)
//     return getKmToM(distance)
//     // return d; // returns the distance in meter
// }

function maskNumbers(string) {
    return string.replace(/\d/g, '*')

}

function maskBanWords(string) {
    var words = string.split(' ')
    for (let index = 0; index < words.length; index++) {
        const element = words[index].toString().toLowerCase();
        // var isBanned = false;
        var bannedIndex = bannedWords.findIndex(x => x.toString().toLowerCase() == element.toString())
        if (bannedIndex > -1) {
            words[index] = "****"
        } else {
            var chunkChar = ""
            for (let index = 0; index < element.length; index++) {
                const el = element.charAt(index);
                if (bannedWordCustomContains.includes(el))
                    chunkChar += "*"
                else chunkChar += el
            }
            words[index] = chunkChar
        }
    }

    return words.join(' ')
}

function checkBanWords(string) {
    var words = []
    bannedWords.forEach(element => {
        // var re = /\b(element)\b/g;
        // var result = re.test(String(string).toLowerCase());
        // string = string.toLowerCase()
        var regex = new RegExp("\\b" + element+ "\\b")
        if (string.search(regex) >= 0) {
            words.push(element)
        }
    });

    bannedWordContains.forEach(element => {
        if (string.includes(element))
            words.push(element)
    });
    return words
}

function checkCustomBanWords(string) {
    var words = []
    bannedWords.forEach(element => {
        // var re = /\b(element)\b/g;
        // var result = re.test(String(string).toLowerCase());
        // string = string
        var regex = new RegExp("\\b" + element + "\\b")
        if (string.search(regex) >= 0) {
            words.push(element)
        }
    });

    bannedWordCustomContains.forEach(element => {
        if (string.includes(element))
            words.push(element)
    });
    return words
}

var isArrayEqual = function (x, y) {
    return differenceWith(x, y, isEqual).isEmpty();
};

var isObjectEqual = function (x, y) {
    return isEqual(x, y)
}

function generateName(id = makeid(8)) {
    // id = str_second_half(id)
    var name = sample(names)
    return name
}

// function str_first_half(str) {
//     str = str.toString()
//     var length = str.length
//     var half = Math.round(length / 2)
//     return str.slice(0, half);
// }

function str_second_half(str) {
    str = str.toString()
    var length = str.length
    var half = Math.round(length / 2)
    var ret = str.slice(half - 1);
    return ret

}
function getOwner(state) {
    try {
        var auth = state.authReducer;
        var session = state.sessionReducer
        var owner = !isJsonObjectEmpty(auth) ? auth.userNumber : session.id
        return owner

    } catch (error) {
        return ''
    }
}




function getSms(sms, userNumber, adId, owner) {
    try {
        if (sms && sms.length > 0) {
            var smsOwner = sms.find(x => x.owner == owner)
            if (smsOwner) {
                return smsOwner.sms.find(x => x.peerId === userNumber && x.adId == adId)
            }
            return {}
        }
        else { return {} }

    } catch (error) {
        return {}
    }
}

function getSmsByOwner(sms, owner) {
    try {
        if (sms) {
            var smsOwner = sms.find(x => x.owner == owner)
            if (smsOwner) {
                return smsOwner.sms
            }
            return {}
        }
        else { return {} }

    } catch (error) {
        return {}
    }
}

function strToBase64(data) {
    let stringToBase64 = Buffer.from(data.toString()).toString('base64');
    return stringToBase64;
}

function base64ToStr(data) {
    let buff = new Buffer(data, 'base64');
    let text = buff.toString('ascii');
    return text;

}

function getFormattedAmount(n, hasDecimal = true) {
    const options = {
        minimumFractionDigits: hasDecimal ? 2 : 0,
        maximumFractionDigits: hasDecimal ? 2 : 0
    };
    if (!n) {
        return '';
    }
    n = parseFloat(n).toFixed(2)
    var withCommas = Number(n).toLocaleString('en', options);
    return withCommas
}

function generateHomeField(item) {
    var images = Object.assign([], item.details.img)
    var index = !images ? -1 : images.findIndex(x => x.display)
    var displayImage = index == -1 ? getSafeImage(null) : images[index].data;
    delete item.details.item

    var result = { ...item, ...item.details }
    result.img = displayImage
    result.video = item.details.video
    return result
}
function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}


function getCurrentTime(store) {
    try {
        var currentTime = store.getState().timeDiscrepancyReducer
        return moment().add(currentTime, 'milliseconds')
        // return moment()
    } catch (error) {
        return moment()
    }

}

function getConvertedTime(date) {
    try {
        return moment(date).tz(getDeviceTimeZone())
        // return moment()
    } catch (error) {
        return moment()
    }

}


function getConvertedTimeFromBackend(date) {
    try {
        // london time
        return moment(date).format("YYYY-MM-DDTHH:mm:ss+01:00")
        // return moment()
    } catch (error) {
        return moment()
    }

}


function toUnicode(str) {
    return str.split('').map(function (value, index, array) {
        var temp = value.charCodeAt(0).toString(16).toLowerCase();
        if (temp.length > 2) {
            return '\\u' + temp;
        }
        return value;
    }).join('');
}

function getDeviceTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}

function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

function getLoginStatus(mins, hours, days) {
    var message = "";

    if (days > 0) {
        if (days > 1) {
            message = "Last Online " + days + " days ago"
        } else {
            message = "Last Online 1 day ago"
        }
    } else if (hours > 0) {
        if (hours > 1) {
            message = "Last Online " + hours + " hours ago"
        } else {
            message = "Last Online 1 hour ago"
        }
    } else if (mins > 0) {
        message = "Last Online 1 hour ago"
    }
    return message
}

function getFormattedHeadline(headline) {
    // const emojiRE = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
    // var emojis = headline.match(emojiRE);
    // if (emojis)
    //     for (let index = 0; index < emojis.length; index++) {
    //         const element = emojis[index];
    //         headline.replace(element, '11')
    //     }
    //     console.log(emojis)
    // return headline
    var chunk = Array.from(headline)
    const emojiRE = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
    var string = ""
    var isEmoji = false
    for (let index = 0; index < chunk.length; index++) {
        const element = chunk[index];

        if (element.match(emojiRE)) {
            if (!isEmoji)
                string += '<span class="emoji">'
            string += '' + element
            isEmoji = true
        }
        else {
            if (isEmoji) {
                string += "</span";
                isEmoji = false
            }
            string += element
        }
    }
    return string
}

export {
    COMMON_STRINGS,
    ACTIONS,
    API_URL,
    ERRORS,
    AGORA_TYPE,
    CALL_STATUS,
    CONNECTION_STATUS,
    MESSAGE_TYPE,
    PROGRESS_TYPE,
    GENDERS,
    CALL_MESSAGE_RULE,
    CONFIG,
    BALANCE_TYPE,
    MESSAGE_STATUS,
    TRANSACTION_PERIOD,
    changePropertyValue,
    escapeRegExp,
    generalDate,
    makeid,
    jsObjectToFormData,
    jsObjectToFormData1,
    isJson,
    stringToArray,
    cleanJsonObject,
    isJsonObjectEmpty,
    isObjectEqual,
    getMiles,
    getKilometers,
    getDisplayDistance,
    maskNumbers,
    getFormattedDate,
    calculateDistance,
    checkBanWords,
    checkCustomBanWords,
    isArrayEqual,
    generateName,
    getOwner,
    getKmToM,
    getSms,
    getSmsByOwner,
    strToBase64,
    base64ToStr,
    getFormattedAmount,
    getSafeImage,
    getGenderIndex,
    getGenderByIndex,
    generateHomeField,
    getCurrentTime,
    formDataToJsObject,
    onlyUnique,
    dataURLtoFile,
    getDataUrl,
    toUnicode,
    maskBanWords,
    toTitleCase,
    getDeviceTimeZone,
    getConvertedTime,
    getConvertedTimeFromBackend,
    getLoginStatus,
    getFormattedHeadline
}