import { ACTIONS } from '../utils/common';

const genderModalReducer = (state = false, action) => {
    switch (action.type) {
        case ACTIONS.GENDER_MODAL:
            return action.payload;
        default: return state;
    }
}

export default genderModalReducer;