import { ACTIONS } from '../utils/common';


const contactsReducer = (state = false, action) => {
    switch (action.type) {
        case ACTIONS.CONTACT:
            return action.payload
        default: return state;
    }
}

export default contactsReducer;