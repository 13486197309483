import { ACTIONS } from '../utils/common';


const adListStatusReducer = (state = {}, action) => {
    switch (action.type) {
        case ACTIONS.ADLIST_STATUS:
            
            return action.payload

        default: return state;
    }
}

export default adListStatusReducer;