import { ACTIONS } from '../utils/common';

const tutorialReducer = (state = false, action) => {
    switch (action.type) {
        case ACTIONS.TUTORIAL:
            return action.payload;
        default: return state;
    }
}

export default tutorialReducer;