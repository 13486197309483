import { ACTIONS } from '../utils/common';

const buyerInfoReducer = (state = {  }, action) => {
    switch (action.type) {
        case ACTIONS.BUYER_INFO:
            return action.payload;
        default: return state;
    }
}

export default buyerInfoReducer;