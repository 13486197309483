const names = [
    'Abel',
    'Abraham',
    'Adam',
    'Addison',
    'Adrian',
    'Ainsley',
    'Alex',
    'Alexis',
    'Andy',
    'Angel',
    'Aaron',
    'Armani',
    'Ash',
    'Asher',
    'Ashley',
    'Ashton',
    'Aubrey',
    'Avery',
    'Bailey',
    'Bartholomew',
    'Bay',
    'Baylor',
    'Benjamin',
    'Billy',
    'Blaine',
    'Blair',
    'Bobbie',
    'Brett',
    'Briar',
    'Brice',
    'Brook',
    'Caden',
    'Cameron',
    'Carmen',
    'Carmine',
    'Carson',
    'Cary',
    'Casey',
    'Cassidy',
    'Cecil',
    'Chandler',
    'Charlie',
    'Christian',
    'Cody',
    'Connor',
    'Corey',
    'Cullen',
    'Dakota',
    'Dallas',
    'Dana',
    'Dane',
    'Daniel',
    'Darren',
    'Darryl',
    'Delaney',
    'Devon',
    'Donovan',
    'Drew',
    'Duncan',
    'Dustin',
    'Dylan',
    'Eddie',
    'Eli',
    'Elisha',
    'Ellery',
    'Emerson',
    'Emory',
    'Erin',
    'Evan',
    'Fabian',
    'Florian',
    'Finley',
    'Frances',
    'Francis',
    'Frankie',
    'Gabriel',
    'Gideon',
    'Glen',
    'Gray',
    'Hadley',
    'Haiden',
    'Harley',
    'Harper',
    'Harrison',
    'Hayden',
    'Hayley',
    'Hunter',
    'Immanuel',
    'Isaac',
    'Jacob',
    'Jade',
    'Jaden',
    'James',
    'Jamie',
    'Jan',
    'Jerry',
    'Jesse',
    'Joel',
    'John',
    'Jordan',
    'Joseph',
    'Joshua',
    'Josiah',
    'Jude',
    'Jules',
    'Julian',
    'Justice',
    'Kaden',
    'Kadin',
    'Kelly',
    'Kelsey',
    'Kendall',
    'Kennedy',
    'Kerry',
    'Kiley',
    'Kimberley',
    'Lake',
    'Lane',
    'Landry',
    'Lee',
    'Levi',
    'Lennon',
    'Lennox',
    'Leslie',
    'Lindsay',
    'Logan',
    'London',
    'Lonnie',
    'Lucas',
    'Lucian',
    'Luke',
    'MacKenzie',
    'Madison',
    'Mallory',
    'Marley',
    'Mason',
    'Matthew',
    'Max',
    'Maximilian',
    'McKenn',
    'Michael',
    'Milan',
    'Montana',
    'Morgan',
    'Moriah',
    'Nevada',
    'Noah',
    'Noel',
    'Oakley',
    'Orion',
    'Paris',
    'Parker',
    'Paul',
    'Payton',
    'Peyton',
    'Perry',
    'Phoenix',
    'Quinn',
    'Raphael',
    'Ravenel',
    'Reagan',
    'Reed',
    'Reese',
    'Regan',
    'Remy',
    'Ren',
    'Reuben',
    'Riley',
    'River',
    'Roan',
    'Robin',
    'Rowan',
    'Royal',
    'Rudy',
    'Ryan',
    'Sage',
    'Samson',
    'Saul',
    'Scout',
    'Sean',
    'Seth',
    'Silas',
    'Simon',
    'Stevie',
    'Shane',
    'Shay',
    'Shelby',
    'Silver',
    'Solomon',
    'Sonny',
    'Spencer',
    'Stephen',
    'Stormy',
    'Sunny',
    'Sydney',
    'Tanner',
    'Tatum',
    'Taylor',
    'Terry',
    'Thaddeus',
    'Thomas',
    'Tony',
    'Tory',
    'Tracy',
    'Tyler',
    'Tyne',
    'Wallace',
    'Wesley',
    'West',
    'Whitney',
    'Wynne',
    'Zane'
]


export { names }

