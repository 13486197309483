import { ACTIONS } from '../utils/common';

const sellerVisitAdReducer = (state = { isRead: false, isShow: false }, action) => {
    switch (action.type) {
        case ACTIONS.SELLER_VISIT_AD:
            return action.payload;
        default: return state;
    }
}

export default sellerVisitAdReducer;