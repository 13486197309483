import { ACTIONS } from '../utils/common';

const adListLocationReducer = (state = { adId: [] }, action) => {
    switch (action.type) {
        case ACTIONS.LOCATION_ADD:
            if (!state.adId.includes(action.payload)) {
                return {
                    ...state,
                    adId: [...state.adId, action.payload]
                }
            } else {
                return {
                    ...state,
                }
            }

        case ACTIONS.LOCATION_REMOVE:
            {
                return {
                    ...state,
                    adId: state.adId.filter(todo => todo !== action.payload)
                }
            }
        case ACTIONS.LOCATION_REMOVE_ALL: {
            return { adId: [] }
        }

        default: return state;
    }
}

export default adListLocationReducer;