import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
// import App from "./App";
import store from "./store";

import './css/app.css';
import AppLoading from "./components/AppLoading";
import { isDesktop } from 'react-device-detect'
import Desktop from "./pages/Desktop/Desktop";
import * as serviceWorker from './serviceWorker';
import { ACTIONS } from "./utils/common";
const App = React.lazy(() => import('./App'))

// import { Router } from "react-router";
// import history from "./history";


if (!isDesktop) {
  // moment.tz.setDefault();
  // moment().tz(process.env.REACT_APP_REGION).format();
  let persistor = persistStore(store);

  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <React.Suspense fallback={<AppLoading isFirstUse={true} />}>
          <App />
        </React.Suspense>
      </PersistGate>
    </Provider>,
    document.getElementById("root")
  );
} else {

  ReactDOM.render(
    <Desktop />,
    document.getElementById("root")
  );
}

serviceWorker.register({
  onSuccess: () => store.dispatch({ type: ACTIONS.SW_INIT }),
  onUpdate: registration => {
    store.dispatch({ type: ACTIONS.SW_UPDATE, payload: registration })
  }
});