import { ACTIONS } from '../utils/common';
import { uniqBy } from '@s-libs/micro-dash';
import orderBy from 'lodash.orderby';

const initialState = {
    hasCall: false,
    history: [],
}

const callHistoryReducer = (state = initialState, action) => {
    var history = state.history
    switch (action.type) {
        case ACTIONS.CALL_HISTORY:
            history = [...state.history, action.payload]
            history = history.filter(x => x.adId)
            history = orderBy(history, ['date'], ['desc'])

            history = uniqBy(history, function (e) {
                return e.adId;
            });
            var hasCall = history.findIndex(x=>x.isRead == false);
            return {
                hasCall: hasCall > -1,
                history: history
            }
        case ACTIONS.CALL_HISTORY_REMOVE:
            return {
                ...state,
                history: state.history.filter(call => call.id !== action.payload.id && call.adId)
            }
        case ACTIONS.CALL_MARK_READ:

            history = history.filter(x => x.adId)
            for (let index = 0; index < history.length; index++) {
                history[index].isRead = true
            }
            return {
                hasCall: false,
                history: history
            }

        default: return state;
    }
}

export default callHistoryReducer;