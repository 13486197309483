import { ACTIONS } from '../utils/common';

const initialState = {
    isOpen : false,
    message : '',
    class : 'success'
}
const messageReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.MESSAGE:
            return action.payload;
        default: return state;
    }
}

export default messageReducer;