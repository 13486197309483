import { ACTIONS } from '../utils/common';

const blacklistReducer = (state = [], action) => {
    switch (action.type) {
        case ACTIONS.BLACKLIST:
            return action.payload
        default: return state;
    }
}

export default blacklistReducer;