import { ACTIONS } from '../utils/common';

const favoriteReducer = (state = { favorites: [] }, action) => {
    switch (action.type) {
        case ACTIONS.FAVOURITE_ADD:
            if (!state.favorites.includes(action.payload)) {
                return {
                    ...state,
                    favorites: [...state.favorites, action.payload]
                }
            } else {
                return {
                    ...state,
                }
            }

        case ACTIONS.FAVOURITE_REMOVE:
            {
                return {
                    ...state,
                    favorites: state.favorites.filter(todo => todo.id !== action.payload.id)
                }
            }
        case ACTIONS.FAVOURITE_REMOVE_ALL: {
            return { favorites: [] }
        }
        case ACTIONS.FAVORITE_UPDATE_STATUS:
            return action.payload
        default: return state;
    }
}

export default favoriteReducer;