import { ACTIONS } from '../utils/common';

const topBarReducer = (state = { isHidden: false, }, action) => {
    switch (action.type) {
        case ACTIONS.TOP_BAR:
            return action.payload;
        default: return state;
    }
}

export default topBarReducer;