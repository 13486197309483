import { ACTIONS } from '../utils/common';

const balanceReducer = (state = {}, action) => {
    switch (action.type) {
        case ACTIONS.BALANCE:
            return action.payload;
        default: return state;
    }
}

export default balanceReducer;