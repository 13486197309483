import { ACTIONS } from '../utils/common';

const locationPermissionReducer = (state = "", action) => {
    switch (action.type) {
        case ACTIONS.LOCATION_PERMISSION:
            return action.payload;
        default: return state;
    }
}

export default locationPermissionReducer;