import { ACTIONS } from '../utils/common';

const currentUserReducer = (state = null, action) => {
    switch (action.type) {
        case ACTIONS.CURRENT_USER:
            return action.payload;
        default: return state;
    }
}

export default currentUserReducer;