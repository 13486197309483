import { ACTIONS } from '../utils/common';

const receivedMessageReducer = (state = false, action) => {
    switch (action.type) {
        case ACTIONS.RECEIVED_MESSAGE:
            return action.payload
        default: return state;
    }
}

export default receivedMessageReducer;