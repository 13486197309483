import { ACTIONS } from '../utils/common';

const resultBlockReducer = (state = '', action) => {
    switch (action.type) {
        case ACTIONS.RESULT_BLOCK:
            return action.payload;
        default: return state;
    }
}

export default resultBlockReducer;