const languages = ['English', 'Spanish', 'French', 'Italian', 'German', 'Portuguese', 'Russian',
    'Chinese', 'Other'];
const servicesList = ['OW', '"A"Levels', 'OWO', 'BDSM', 'PSE',
    'Dining', 'Quickie', 'Domination', 'Role Play', 'DUO', 'Stress Relief',
    'Escorting', 'Strip-tease', 'Fetish', 'Travel', 'GFE/BFE', 'WS', 'Massage',
    'Phone', 'Modelling', 'Webcam'
];

const initialRates = [
    {
        duration: "15 mins",
        inCallRate: '0',
        outCallRate: '0'
    }, {
        duration: "30 mins",
        inCallRate: '0',
        outCallRate: '0'
    }, {
        duration: "1 hour",
        inCallRate: '0',
        outCallRate: '0'
    }, {
        duration: "2 hours",
        inCallRate: '0',
        outCallRate: '0'
    },
    {
        duration: "3 hours",
        inCallRate: '0',
        outCallRate: '0'
    }, {
        duration: "4 hours",
        inCallRate: '0',
        outCallRate: '0'
    },
    {
        duration: "Overnight",
        inCallRate: '0',
        outCallRate: '0'
    },
]

const outCallRates = ['15 mins', '30 mins', '1 hour', '2 hours', '3 hours', '4 hours', 'Overnight'];
const inCallRates = ['15 mins', '30 mins', '1 hour', '2 hours', '3 hours', '4 hours', 'Overnight'];
const servicesFor = ['Men', 'Women', 'Couples'];
const ethnicity = ['Arabic', 'Asian', 'African/Caribbean', 'Caucasian', 'Indian', 'Latina/o', 'Other']
const ethnicityForFilter = ['Arabic', 'Asian', 'African/Caribbean', 'Caucasian', 'Indian', 'Latina/o', 'Other']

const typeOfAd = ['Independent', 'Agency Worker']
const genders = ['Female', 'Male', 'Trans']
const gendersFilter = ['Female', 'Male', 'Trans', "All"]

const unitData = [{ name: "Imperial (U.S)", value: 0 }, { name: "Metric", value: 1 }]


const tutorialData = {
    "message": "Success",
    "code": 200,
    "success": true,
    "result": {
        "rows": [
            {
                "age": 32,
                "distance": "unknow",
                "gender": "Female",
                "id": 186,
                "img": "http://hk-chicca.oss-cn-hangzhou.aliyuncs.com/chicca/storage/topic/app_assets/images/samples/ad-01.jpg",
                "isNew": true,
                "isOnline": true,
                "isOnlineDesc": "",
                "items": 1,
                "location": [],
                "name": "Xoxo",
                "profileId": 90,
                "setting": {
                    "isReceivedChat": 1,
                    "isReceivedCall": 1,
                    "hasSounds": 1,
                    "isPreciseLocation": 1,
                    "isShowInExploreSearch": 1,
                    "isShowDistance": 1,
                    "isIncognito": 0,
                    "unit_system": 0,
                    "unitSystem": "meters"
                },
                "typeOfAd": "Independent",
                "userNumber": "123456",
                "video": "abcdefg"
            },
            {
                "age": 30,
                "distance": "unknow",
                "gender": "Female",
                "id": 187,
                "img": "http://hk-chicca.oss-cn-hangzhou.aliyuncs.com/chicca/storage/topic/app_assets/images/samples/ad-02.jpg",
                "isNew": false,
                "isOnline": true,
                "isOnlineDesc": "",
                "items": 1,
                "location": [],
                "name": "Xyrille",
                "profileId": 91,
                "setting": {
                    "isReceivedChat": 1,
                    "isReceivedCall": 1,
                    "hasSounds": 1,
                    "isPreciseLocation": 1,
                    "isShowInExploreSearch": 1,
                    "isShowDistance": 1,
                    "isIncognito": 0,
                    "unit_system": 0,
                    "unitSystem": "meters"
                },
                "typeOfAd": "Independent",
                "userNumber": "123456",
                "video": ""
            },
            {
                "age": 23,
                "distance": "unknow",
                "gender": "Female",
                "id": 189,
                "img": "http://hk-chicca.oss-cn-hangzhou.aliyuncs.com/chicca/storage/topic/app_assets/images/samples/ad-03.jpg",
                "isNew": false,
                "isOnline": false,
                "isOnlineDesc": "",
                "items": 1,
                "location": [],
                "name": "Cristine",
                "profileId": 93,
                "setting": {
                    "isReceivedChat": 1,
                    "isReceivedCall": 1,
                    "hasSounds": 1,
                    "isPreciseLocation": 1,
                    "isShowInExploreSearch": 1,
                    "isShowDistance": 1,
                    "isIncognito": 0,
                    "unit_system": 0,
                    "unitSystem": "meters"
                },
                "typeOfAd": "Independent",
                "userNumber": "123456",
                "video": ""
            },
            {
                "age": 22,
                "distance": "0.765",
                "gender": "Female",
                "id": 95,
                "img": "http://hk-chicca.oss-cn-hangzhou.aliyuncs.com/chicca/storage/topic/app_assets/images/samples/ad-04.jpg",
                "isNew": false,
                "isOnline": true,
                "isOnlineDesc": "1 days ago",
                "items": 3,
                "location": [],
                "name": "Emma",
                "profileId": 45,
                "setting": {
                    "isReceivedChat": 1,
                    "isReceivedCall": 1,
                    "hasSounds": 1,
                    "isPreciseLocation": 1,
                    "isShowInExploreSearch": 1,
                    "isShowDistance": 1,
                    "isIncognito": 0,
                    "unit_system": 0,
                    "unitSystem": "meters"
                },
                "typeOfAd": "Independent",
                "userNumber": "123456",
                "video": ""
            },
            {
                "age": 22,
                "distance": "0.767",
                "gender": "Female",
                "id": 205,
                "img": "http://hk-chicca.oss-cn-hangzhou.aliyuncs.com/chicca/storage/topic/app_assets/images/samples/ad-05.jpg",
                "isNew": false,
                "isOnline": true,
                "isOnlineDesc": "1 days ago",
                "items": 2,
                "location": [],
                "name": "Amme",
                "profileId": 45,
                "setting": {
                    "isReceivedChat": 1,
                    "isReceivedCall": 1,
                    "hasSounds": 1,
                    "isPreciseLocation": 1,
                    "isShowInExploreSearch": 1,
                    "isShowDistance": 1,
                    "isIncognito": 0,
                    "unit_system": 0,
                    "unitSystem": "meters"
                },
                "typeOfAd": "Independent",
                "userNumber": "123456",
                "video": ""
            },
            {
                "age": 24,
                "distance": "0.779",
                "gender": "Female",
                "id": 188,
                "img": "http://hk-chicca.oss-cn-hangzhou.aliyuncs.com/chicca/storage/topic/app_assets/images/samples/ad-06.jpg",
                "isNew": false,
                "isOnline": false,
                "isOnlineDesc": "",
                "items": 1,
                "location": [],
                "name": "Kim",
                "profileId": 92,
                "setting": {
                    "isReceivedChat": 1,
                    "isReceivedCall": 1,
                    "hasSounds": 1,
                    "isPreciseLocation": 1,
                    "isShowInExploreSearch": 1,
                    "isShowDistance": 1,
                    "isIncognito": 0,
                    "unit_system": 0,
                    "unitSystem": "meters"
                },
                "typeOfAd": "Independent",
                "userNumber": "123456",
                "video": ""
            },
            {
                "age": 21,
                "distance": "24.374",
                "gender": "Female",
                "id": 183,
                "img": "http://hk-chicca.oss-cn-hangzhou.aliyuncs.com/chicca/storage/topic/app_assets/images/samples/ad-07.jpg",
                "isNew": false,
                "isOnline": true,
                "isOnlineDesc": "5 days ago",
                "items": 1,
                "location": [],
                "name": "Casandra",
                "profileId": 60,
                "setting": {
                    "isReceivedChat": 1,
                    "isReceivedCall": 1,
                    "hasSounds": 1,
                    "isPreciseLocation": 1,
                    "isShowInExploreSearch": 1,
                    "isShowDistance": 1,
                    "isIncognito": 1,
                    "unit_system": 0,
                    "unitSystem": "meters"
                },
                "typeOfAd": "Independent",
                "userNumber": "123456",
                "video": ""
            },
            {
                "age": 21,
                "distance": "24.374",
                "gender": "Female",
                "id": 62,
                "img": "http://hk-chicca.oss-cn-hangzhou.aliyuncs.com/chicca/storage/topic/app_assets/images/samples/ad-08.jpg",
                "isNew": false,
                "isOnline": true,
                "isOnlineDesc": "3 days ago",
                "items": 1,
                "location": [],
                "name": "Incognito",
                "profileId": 43,
                "setting": {
                    "isReceivedChat": 1,
                    "isReceivedCall": 1,
                    "hasSounds": 1,
                    "isPreciseLocation": 1,
                    "isShowInExploreSearch": 1,
                    "isShowDistance": 1,
                    "isIncognito": 0,
                    "unit_system": 0,
                    "unitSystem": "meters"
                },
                "typeOfAd": "Independent",
                "userNumber": "123456",
                "video": ""
            },
            {
                "age": 21,
                "distance": "24.374",
                "gender": "Female",
                "id": 71,
                "img": "http://hk-chicca.oss-cn-hangzhou.aliyuncs.com/chicca/storage/topic/app_assets/images/samples/ad-09.jpg",
                "isNew": false,
                "isOnline": true,
                "isOnlineDesc": "3 days ago",
                "items": 2,
                "location": [],
                "name": "Serena",
                "profileId": 55,
                "setting": {
                    "isReceivedChat": 1,
                    "isReceivedCall": 1,
                    "hasSounds": 1,
                    "isPreciseLocation": 1,
                    "isShowInExploreSearch": 1,
                    "isShowDistance": 1,
                    "isIncognito": 0,
                    "unit_system": 0,
                    "unitSystem": "meters"
                },
                "typeOfAd": "Independent",
                "userNumber": "123456",
                "video": "video"
            },
            {
                "age": 25,
                "distance": "24.374",
                "gender": "Female",
                "id": 93,
                "img": "http://hk-chicca.oss-cn-hangzhou.aliyuncs.com/chicca/storage/topic/app_assets/images/samples/ad-10.jpg",
                "isNew": false,
                "isOnline": true,
                "isOnlineDesc": "3 days ago",
                "items": 2,
                "location": [],
                "name": "Ruby",
                "profileId": 46,
                "setting": {
                    "isReceivedChat": 1,
                    "isReceivedCall": 1,
                    "hasSounds": 1,
                    "isPreciseLocation": 1,
                    "isShowInExploreSearch": 1,
                    "isShowDistance": 1,
                    "isIncognito": 1,
                    "unit_system": 0,
                    "unitSystem": "meters"
                },
                "typeOfAd": "Independent",
                "userNumber": "123456",
                "video": ""
            },
            {
                "age": 25,
                "distance": "24.374",
                "gender": "Female",
                "id": 76,
                "img": "http://hk-chicca.oss-cn-hangzhou.aliyuncs.com/chicca/storage/topic/app_assets/images/samples/ad-11.jpg",
                "isNew": false,
                "isOnline": true,
                "isOnlineDesc": "3 days ago",
                "items": 2,
                "location": [],
                "name": "Giselle",
                "profileId": 55,
                "setting": {
                    "isReceivedChat": 1,
                    "isReceivedCall": 1,
                    "hasSounds": 1,
                    "isPreciseLocation": 1,
                    "isShowInExploreSearch": 1,
                    "isShowDistance": 1,
                    "isIncognito": 0,
                    "unit_system": 0,
                    "unitSystem": "meters"
                },
                "typeOfAd": "Independent",
                "userNumber": "123456",
                "video": ""
            },
            {
                "age": 24,
                "distance": "24.374",
                "gender": "Female",
                "id": 89,
                "img": "http://hk-chicca.oss-cn-hangzhou.aliyuncs.com/chicca/storage/topic/app_assets/images/samples/ad-12.jpg",
                "isNew": false,
                "isOnline": true,
                "isOnlineDesc": "3 days ago",
                "items": 1,
                "location": [],
                "name": "Lina",
                "profileId": 55,
                "setting": {
                    "isReceivedChat": 1,
                    "isReceivedCall": 1,
                    "hasSounds": 1,
                    "isPreciseLocation": 1,
                    "isShowInExploreSearch": 1,
                    "isShowDistance": 1,
                    "isIncognito": 0,
                    "unit_system": 0,
                    "unitSystem": "meters"
                },
                "typeOfAd": "Agency Worker",
                "userNumber": "123456",
                "video": "video"
            },
        ],
        "pageSize": 24,
        "pageIndex": 1,
        "totalPage": 1,
        "total": 12
    }
}

const bannedWords = [
    'snap',
    'snapchat',
    'kik',
    'insta',
    'instagram',
    'IG',
    'ig',
    'telegram',
    'tele',
    'whatsapp',
    'fb',
    'facebook',
    'faceb',
    'twitter',
    'twit',
    'onlyfans',
    'Snapchat',
    'Snap',
    'Kik',
    'Insta',
    'Instagram',
    'Telegram',
    'Telev',
    'Whatsapp',
    'FB',
    'Facebook',
    'FaceBook',
    'Twitter',
    'Twit',
    'Onlyfans',
    'Signal',
    'Discord',
    'discord',
    'Epal',
    'epal',
    'Steam',
    'steam',
    'weibo',
    'wb',
    'Kakaotalk',
    'kkt',
    'Kakaotalk',
    'Cyworld',
    'cyworld',
    'BAND',
    'DAUM',
    'daum',
    'WeChat',
    'wechat',
    'Sina Weibo',
    'Weibo',
    'weibo',
    'Tencent',
    'tencent',
    'XHS',
    'Xiao Hong Shu',
    'xhs',
    'DouYin',
    'douyin',
    'Tiktok',
    'tiktok',
    'ttk',
    'tk',
    'Douban',
    'douban',
    'Renren',
    'RenRen',
    'renren',
    'Tumblr',
    'tumblr',
    'Reddit',
    'reddit',
    'Tag',
    'tag',
]
const bannedWordContains = [
    '@',
    // '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'
]

const bannedWordCustomContains = [
    '@',
    // '1', '2', '3', '4', '5', '7', '8', '0'
]


export {
    languages,
    servicesList,
    initialRates,
    outCallRates,
    inCallRates,
    servicesFor,
    ethnicity,
    typeOfAd, genders,gendersFilter,
    unitData, ethnicityForFilter,
    tutorialData,
    bannedWords,
    bannedWordContains, bannedWordCustomContains
}