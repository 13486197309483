import { ACTIONS } from '../utils/common';

const selectUserAdReducer = (state = {}, action) => {
    switch (action.type) {
        case ACTIONS.SELECT_USER_AD:
            return action.payload;
        default: return state;
    }
}

export default selectUserAdReducer;