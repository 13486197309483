import { ACTIONS } from '../utils/common';

const settingReducer = (state = '', action) => {
    switch (action.type) {
        case ACTIONS.SETTING:
            return action.payload;
        default: return state;
    }
}

export default settingReducer;