import { ACTIONS } from '../utils/common';

const addressReducer = (state = {longitude : 0,latitude : 0}, action) => {
    switch (action.type) {
        case ACTIONS.ADDRESS:
            return action.payload;
        default: return state;
    }
}

export default addressReducer;