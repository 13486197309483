import { ACTIONS } from '../utils/common';


const adListFilterReducer = (state = {}, action) => {
    switch (action.type) {
        case ACTIONS.FILTER_ADLIST:
            return action.payload
        default: return state;
    }
}

export default adListFilterReducer;