import { LinearProgress, Typography } from "@material-ui/core";
import React from "react";
import logo from '../assets/images/chicca-vertical-logo.webp'

export const AppLoading = (props) => {
  return (<span className="center-div-1">
    <img src={logo} style={{ width: "150px" }}></img>
    {/* {props.isFirstUse && <React.Fragment>

      <Typography className="text-center white-font small-font">Please wait while we download resources </Typography>

      <Typography className="text-center white-font small-font"> (for first time use)</Typography>
    </React.Fragment>
    } */}
    <LinearProgress color="primary" />
  </span>)
}



export default (AppLoading);

