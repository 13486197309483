import React, { Component } from 'react'
import '../../css/desktop.css'
import image1 from '../../assets/images/image-1.webp'
import desktop1 from '../../assets/images/desktop1.webp'
import desktop2 from '../../assets/images/desktop2.webp'
import desktop3 from '../../assets/images/desktop3.webp'

import Carousel from 'react-material-ui-carousel'

export default class Desktop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: 1
        }
    }

    newPage = () => {
        var self = this
        setTimeout(() => {
            var newOrder = self.state.order + 1;
            if (newOrder > 3) {
                newOrder = 1
            }
            self.setState({ order: newOrder })
            self.newPage()
        }, 5000);
    }
    componentDidMount() {
        this.newPage()
    }

    render() {
        return (<React.Fragment>
            <table
                className="table3"
                cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr className="tr3">
                        <td className="td2">
                            <table width="100%" cellPadding="0" cellSpacing="0" border="0">
                                <tbody>
                                    <tr>
                                        <td align="center" className="td4">
                                            <div className="u-row-container div1">
                                                <div className="u-row div2">
                                                    <div className="div3">
                                                        <table width="100%" cellPadding="0" cellSpacing="0" border="0">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="td5" align="center">
                                                                        <table cellPadding="0" cellSpacing="0" border="0" className="table4">
                                                                            <tbody>
                                                                                <tr className="tr1">

                                                                                    <td align="center" width="500" className="td6" valign="top">
                                                                                        <div className="u-col u-col-100 div4">
                                                                                            <div className="div5">

                                                                                                <div
                                                                                                    className="div6">
                                                                                                    <table className="table1" role="presentation" cellPadding="0"
                                                                                                        cellSpacing="0" width="100%" border="0">
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td
                                                                                                                    className="td1"
                                                                                                                    align="left">

                                                                                                                    <table width="100%" cellPadding="0" cellSpacing="0" border="0">
                                                                                                                        <tbody>
                                                                                                                            <tr>
                                                                                                                                <td className="td6" align="center">

                                                                                                                                    <img align="center" border="0" src={image1} alt="ChiccaChicca Logo"
                                                                                                                                        title="ChiccaChicca Logo"
                                                                                                                                        className="image1"
                                                                                                                                        width="480" />


                                                                                                                                    <h1 className="h1">ONLY
                                                                                                                                        AVAILABLE ON MOBILE DEVICES</h1>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>

                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>

                                                                                                    <table className="table1" role="presentation" cellPadding="0"
                                                                                                        cellSpacing="0" width="100%" border="0">
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td
                                                                                                                    className="td1"
                                                                                                                    align="left">

                                                                                                                    <table height="0px" align="center" border="0" cellPadding="0" cellSpacing="0" width="100%" className="table2">
                                                                                                                        <tbody>
                                                                                                                            <tr className="tr3">
                                                                                                                                <td
                                                                                                                                    className="td7">
                                                                                                                                    <span>&#160;</span>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>

                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>

                                                                                                    <table className="table1" role="presentation" cellPadding="0"
                                                                                                        cellSpacing="0" width="100%" border="0">
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td
                                                                                                                    className="td8"
                                                                                                                    align="left">

                                                                                                                    <h1
                                                                                                                        className="h1">
                                                                                                                        FIND YOUR NEAREST IMMEDIATELY-AVAILABLE LONDON ESCORTS!
                                                                                                                    </h1>

                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>

                                                                                                    <table className="table1" role="presentation" cellPadding="0"
                                                                                                        cellSpacing="0" width="100%" border="0">
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td
                                                                                                                    className="td1"
                                                                                                                    align="left">

                                                                                                                    <h3
                                                                                                                        className="h3">
                                                                                                                        100% FREE TO USE<br />COMPLETELY CONFIDENTIAL<br />WHATEVER YOU WANT, WHENEVER YOU WANT IT
                                                                                                                    </h3>

                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>

                                                                                                    <table className="table1" role="presentation" cellPadding="0"
                                                                                                        cellSpacing="0" width="100%" border="0">
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td
                                                                                                                    className="td1"
                                                                                                                    align="left">

                                                                                                                    <table width="100%" cellPadding="0" cellSpacing="0" border="0">
                                                                                                                        <tbody>
                                                                                                                            <tr>
                                                                                                                                <td className="td6" align="center">

                                                                                                                                    <Carousel
                                                                                                                                        cycleNavigation={true}
                                                                                                                                        autoPlay={true}
                                                                                                                                        // stopAutoPlayOnHover={true}
                                                                                                                                        navButtonsAlwaysVisible={false}
                                                                                                                                        animation="fade"
                                                                                                                                        indicators={false}
                                                                                                                                        interval={5000}
                                                                                                                                        className="carousel1"
                                                                                                                                    >
                                                                                                                                        <img align="center" border="0" src={desktop1} alt="" title=""
                                                                                                                                            className="image1"
                                                                                                                                           />

                                                                                                                                        <img align="center" border="0" src={desktop2} alt="" title=""
                                                                                                                                            className="image1"
                                                                                                                                            />

                                                                                                                                        <img align="center" border="0" src={desktop3} alt="" title=""
                                                                                                                                            className="image1"
                                                                                                                                             />
                                                                                                                                    </Carousel>



                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>

                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>

                                                                                                    <table className="table1" role="presentation" cellPadding="0"
                                                                                                        cellSpacing="0" width="100%" border="0">
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td
                                                                                                                    className="td1"
                                                                                                                    align="left">

                                                                                                                    <table height="0px" align="center" border="0" cellPadding="0" cellSpacing="0" width="100%"
                                                                                                                        className="table2">
                                                                                                                        <tbody>
                                                                                                                            <tr className="tr3">
                                                                                                                                <td className="td7">
                                                                                                                                    <span>&#160;</span>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>

                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>

                                                                                                    <table className="table1" role="presentation" cellPadding="0"
                                                                                                        cellSpacing="0" width="100%" border="0">
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td
                                                                                                                    className="td1"
                                                                                                                    align="left">

                                                                                                                    <div
                                                                                                                        className="div7">
                                                                                                                        <p className="p1"><span
                                                                                                                            className="p2">ONLY
                                                                                                                            AVAILABLE ON MOBILE DEVICES</span></p>
                                                                                                                        <p className="p1"> </p>
                                                                                                                        <p className="p1"><span
                                                                                                                            className="p2">The reason more and more clients are switching from old-school listings sites to ChiccaChicca, is because our unique real-time availability & geolocation technology tells them the two things they want to know most, which are: who’s free right now, and how near are they?
                                                                                                                        </span></p>
                                                                                                                        <p className="p1"> </p>


                                                                                                                        <p className="p1"><span
                                                                                                                            className="p2">That’s why escorts know that when a ChiccaChicca client calls, they really mean business, and they’re probably not far away!
                                                                                                                        </span></p>
                                                                                                                        <p className="p1"> </p>

                                                                                                                        <p className="p1"><span
                                                                                                                            className="p2">To access ChiccaChicca, you need to use a mobile device with ‘location tracking’ enabled. You will find this in ‘settings’.
                                                                                                                        </span></p>

                                                                                                                        <p className="p1"> </p>

                                                                                                                        <p className="p1"><span
                                                                                                                            className="p2"> ChiccaChicca does not support desktop devices.
                                                                                                                        </span></p>

                                                                                                                    </div>

                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>

                                                                                                    <table className="table1" role="presentation" cellPadding="0"
                                                                                                        cellSpacing="0" width="100%" border="0">
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td
                                                                                                                    className="td1"
                                                                                                                    align="left">

                                                                                                                    <table height="0px" align="center" border="0" cellPadding="0" cellSpacing="0" width="100%"
                                                                                                                        className="table2">
                                                                                                                        <tbody>
                                                                                                                            <tr className="tr2">
                                                                                                                                <td
                                                                                                                                    className="td7">
                                                                                                                                    <span>&#160;</span>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>

                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>


                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>


                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table >
        </React.Fragment >
        )
    }
}
