import { ACTIONS } from '../utils/common';

const blockAlertReducer = (state = { isShow: false, type: 1, message: '' }, action) => {
    switch (action.type) {
        case ACTIONS.BLOCK_ALERT:
            return action.payload;
        default: return state;
    }
}

export default blockAlertReducer;