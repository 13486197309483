import { ACTIONS } from '../utils/common';

const topUpReducer = (state = false, action) => {
    switch (action.type) {
        case ACTIONS.TOP_UP:
            return action.payload;
        default: return state;
    }
}

export default topUpReducer;